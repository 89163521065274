// Name:            Progress
// Description:     Component to create progress bars
//
// Component:       `uk-progress`
//
// ========================================================================


// Variables
// ========================================================================

@progress-height:                               15px;
@progress-margin-vertical:                      @global-margin;
@progress-background:                           @global-muted-background;

@progress-bar-background:                       @global-primary-background;


/* ========================================================================
   Component: Progress
 ========================================================================== */

/*
 * 1. Remove default style
 * 2. Behave like a block element
 * 3. Remove borders in Firefox and Edge
 * 4. Set background color for progress container in Firefox, IE11 and Edge
 * 5. Style
 */

.uk-progress {
    /* 1 */
    -webkit-appearance: none;
    -moz-appearance: none;
    /* 2 */
    display: block;
    width: 100%;
    /* 3 */
    border: 0;
    /* 4 */
    background-color: @progress-background;
    /* 5 */
    margin-bottom: @progress-margin-vertical;
    height: @progress-height;
    .hook-progress;
}

/* Add margin if adjacent element */
* + .uk-progress { margin-top: @progress-margin-vertical; }

/*
 * Remove animated circles for indeterminate state in IE11 and Edge
 */

.uk-progress:indeterminate { color: transparent; }

/*
 * Progress container
 * 2. Remove progress bar for indeterminate state in Firefox
 */

.uk-progress::-webkit-progress-bar {
  background-color: @progress-background;
  .hook-progress;
}

/* 2 */
.uk-progress:indeterminate::-moz-progress-bar { width: 0; }

/*
 * Progress bar
 * 1. Remove right border in IE11 and Edge
 */

.uk-progress::-webkit-progress-value {
    background-color: @progress-bar-background;
    transition: width 0.6s ease;
    .hook-progress-bar;
}

.uk-progress::-moz-progress-bar {
    background-color: @progress-bar-background;
    .hook-progress-bar;
}

.uk-progress::-ms-fill {
    background-color: @progress-bar-background;
    transition: width 0.6s ease;
    /* 1 */
    border: 0;
    .hook-progress-bar;
}


// Hooks
// ========================================================================

.hook-progress-misc;

.hook-progress() {}
.hook-progress-bar() {}
.hook-progress-misc() {}

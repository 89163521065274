/* ========================================================================
   Zoom image
 ========================================================================== */
 
.mythumbnail {
  position: relative;
  max-width: 120px;
  
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  
  .transition(max-width 1s);
}


.is-active .mythumbnail {
	max-width: 90%;
  cursor: pointer;
  
  cursor: -webkit-zoom-out;
  cursor: -moz-zoom-out;
}
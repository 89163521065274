// Footer
//----------------------------

footer {
	padding-top: 80px;
	height: 116px;
	text-align: center;
	font-size: 12px;
	
	br {
		display: none;
	}
	
	hr {
		border-top-color: #c5c5c5;
	}
	
	nav {
		padding-bottom: 40px;
		
		a {
			color: @gray-light;
			text-decoration: none;
			margin: 0 20px;
			
			&:hover {
				color: @brand-primary;
			}
		}
	}
}

// Responsive
//----------------------------

@media only screen and (max-width: 420px) {	
	
	footer {
		
		br {
			display: block;
		}
		
		nav {
			max-width: 200px;
			margin: 0 auto;
			
			a {
				display: block;
				padding: 8px 0;
				margin: 0 auto;
				width: 100%;
			}
		}
	}
}
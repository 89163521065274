// Import
//----------------------------
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700,900');

@import '../uikit/uikit.less';

@import 'vendor/uikit';
@import 'vendor/zoomimage';

@import 'abstracts/variables';
@import 'abstracts/vendor-prefixes';
@import 'abstracts/mixins';
@import 'abstracts/helpers';

@import 'base/base';
@import 'base/typography';

@import 'layout/navigation';
@import 'layout/jumbotron';
@import 'layout/footer';
@import 'layout/forms';

@import 'components/preloader';
@import 'components/buttons';
@import 'components/alert';
@import 'components/card';
@import 'components/dropzone';
@import 'components/modal';

@import 'pages/backoffice';

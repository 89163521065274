// Name:            Visibility
// Description:     Utilities to show or hide content on breakpoints, hover or touch
//
// Component:       `uk-hidden-*`
//                  `uk-visible-*`
//                  `uk-invisible`
//                  `uk-visible-toggle`
//                  `uk-hidden-hover`
//                  `uk-invisible-hover`
//                  `uk-hidden-touch`
//                  `uk-hidden-notouch`
//
// ========================================================================


/* ========================================================================
   Component: Visibility
 ========================================================================== */

/*
 * Hidden
 * `hidden` attribute also set here to make it stronger
 */

[hidden],
.uk-hidden { display: none !important; }

/* Phone landscape and bigger */
@media (min-width: @breakpoint-small) {

    .uk-hidden\@s { display: none !important; }

}

/* Tablet landscape and bigger */
@media (min-width: @breakpoint-medium) {

    .uk-hidden\@m { display: none !important; }

}

/* Desktop and bigger */
@media (min-width: @breakpoint-large) {

    .uk-hidden\@l { display: none !important; }

}

/* Large screen and bigger */
@media (min-width: @breakpoint-xlarge) {

    .uk-hidden\@xl { display: none !important; }

}

/*
 * Visible
 */

/* Phone portrait and smaller */
@media (max-width: @breakpoint-xsmall-max) {

    .uk-visible\@s { display: none !important; }

}

/* Phone landscape and smaller */
@media (max-width: @breakpoint-small-max) {

    .uk-visible\@m { display: none !important; }

}

/* Tablet landscape and smaller */
@media (max-width: @breakpoint-medium-max) {

    .uk-visible\@l { display: none !important; }

}

/* Desktop and smaller */
@media (max-width: @breakpoint-large-max) {

    .uk-visible\@xl { display: none !important; }

}


/* Visibility
 ========================================================================== */

.uk-invisible { visibility: hidden !important; }


/* Hover
 ========================================================================== */

/*
 * Hidden
 * Can't use `display: hidden` because it's not focusable. This is accessible through keyboard.
 */

.uk-visible-toggle:not(:hover):not(.uk-hover) .uk-hidden-hover:not(:focus) {
    position: absolute !important;
    width: 0 !important;
    height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: hidden !important;
}

/*
 * Invisible
 * Can't use `visibility: hidden` because it's not focusable. This is accessible through keyboard.
 */

.uk-visible-toggle:not(:hover):not(.uk-hover) .uk-invisible-hover:not(:focus) { opacity: 0 !important; }


/* Touch
 ========================================================================== */

/*
 * Hide if primary pointing device has limited accuracy, e.g. a touch screen.
 * Works on mobile browsers: Safari, Chrome and Android browser
 */

@media (pointer: coarse) {
    .uk-hidden-touch { display: none !important; }
}

/*
 * Hide if primary pointing device is accurate, e.g. mouse.
 * 1. Fallback for IE11 and Firefox, because `pointer` is not supported
 * 2. Reset if supported
 */

/* 1 */
.uk-hidden-notouch { display: none !important; }

@media (pointer: coarse) {
    .uk-hidden-notouch { display: block !important; }
}


// Hooks
// ========================================================================

.hook-visibility-misc;

.hook-visibility-misc() {}

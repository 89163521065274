// Typography
//----------------------------


.uk-text-medium {
	font-size: 20px;
}

.uk-text-normal {
	font-weight: normal !important;
}

.uk-text-small {
	//line-height: 1 !important;
}


.home p {
	line-height: 1.7;
}


// Responsive
//----------------------------

@media only screen and (max-width: 767px) {	
	
	h1, .h1 {
	  margin-bottom: 30px;
	}
	
	h1, .h1 {
		font-size: 42px;
	}
	h2, .h2 {
		font-size: 32px;
	}
}


@media only screen and (max-width: 580px) {	
	
	h1, .h1 {
		font-size: 36px;
	}
}


@media only screen and (max-width: 479px) {	
	
	h1, .h1 {
		font-size: 32px;
	}
	h2, .h2 {
		font-size: 28px;
	}
	
	h3, .uk-h3 {
		font-size: 20px;
	}
}
// UIKIT custom
//----------------------------


/* FORM */

@internal-form-select-image:                     "../images/uikit/backgrounds/form-select.svg";
@internal-form-radio-image:                      "../images/uikit/images/backgrounds/form-radio.svg";
@internal-form-checkbox-image:                   "../images/uikit/images/backgrounds/form-checkbox.svg";
@internal-form-checkbox-indeterminate-image:     "../images/uikit/backgrounds/form-checkbox-indeterminate.svg";


.uk-checkbox:checked {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23fff%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}

.uk-radio:checked {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E");
}




/* Responsive table
 ========================================================================== */


/* Phone landscape and smaller */
@media (max-width: @breakpoint-medium-max) {

    .uk-table-responsive,
    .uk-table-responsive tbody,
    .uk-table-responsive th,
    .uk-table-responsive td,
    .uk-table-responsive tr { display: block; }

    .uk-table-responsive thead { display: none; }

    .uk-table-responsive th,
    .uk-table-responsive td {
        width: auto !important;
        max-width: none !important;
        min-width: 0 !important;
        overflow: visible !important;
        white-space: normal !important;
    }

    .uk-table-responsive th:not(:first-child):not(.uk-table-link),
    .uk-table-responsive td:not(:first-child):not(.uk-table-link),
    .uk-table-responsive .uk-table-link:not(:first-child) > a { padding-top: round(@table-cell-padding-vertical / 3) !important; }

    .uk-table-responsive th:not(:last-child):not(.uk-table-link),
    .uk-table-responsive td:not(:last-child):not(.uk-table-link),
    .uk-table-responsive .uk-table-link:not(:last-child) > a { padding-bottom: round(@table-cell-padding-vertical / 3) !important; }

    .uk-table-justify.uk-table-responsive th,
    .uk-table-justify.uk-table-responsive td {
        padding-left: 0;
        padding-right: 0;
    }

}
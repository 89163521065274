/* ========================================================================
   Alert
 ========================================================================== */
 
.navbar + .uk-alert {
    margin-top: -1px;
}

.uk-alert {

	.uk-close {
		top: 14px;
		padding: 6px;
		border-radius: 50%;
	  color: rgba(255,255,255,0.7);
		background-color: rgba(0,0,0,0.3);
		
		&:hover {
			color: #fff;
		}
	}
}


.uk-alert-success {
	background: #00BD9C;
	border: 1px solid #019b80;
	box-shadow: 0 1px 3px 0 rgba(0,0,0,0.35);
	
	color: #fff;
}


.uk-alert-warning {
	background: #F5A623;
	border: 1px solid #bd7e16;
	box-shadow: 0 1px 3px 0 rgba(0,0,0,0.36);
	
	color: #fff;
}


.uk-alert-danger {
	background: #B61904;
	border: 1px solid #861505;
	box-shadow: 0 1px 3px 0 rgba(0,0,0,0.35);
	
	color: #fff;
}
// Helpers (Utilities)
//----------------------------

.width-80 {
	width: 80px;
}

.width-100 {
	width: 100px;
}

.width-120 {
	width: 120px;
}

.width-160 {
	width: 160px;
}

.width-180 {
	width: 180px;
}

.width-200 {
	width: 200px;
}

.width-280 {
	width: 280px;
}



.height-70 {
	height: 70px;
}

.height-100 {
	height: 100px;
}



.padding-bottom-30 {
	padding-bottom: 30px;
}
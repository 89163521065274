/* ========================================================================
   Backoffice
 ========================================================================== */
 
.bo {
	
	.uk-table {
		border-left: 1px solid @gray-lighter;
		border-bottom: 1px solid @gray-lighter;
		
		td {
			border-right: 1px solid @gray-lighter;
			font-size: 14px;
		}
		
		th {
			color: #fff;
			font-weight: 400;
			font-size: 12px;
			vertical-align: top;
			text-transform: uppercase;
			background-color: @brand-secondary;
			border-right: 1px solid rgba(255,255,255,0.2);
		}
			
		.empty-row {
			background-color: @gray-lighter;
		}
		
		&.table-width-auto {
			width: auto;
		}
		
		.autofix-container {
			
			img {
				width: 120px;
				max-width: 120px;
				height: auto;
				.transition(all .2s ease);
				
				&.autofix-hover {
					position: relative;
					z-index: 100;
					.scale(1.8);
					box-shadow: 0 0 4px 0 rgba(0,0,0,0.35);
				}
			}
		}
		
		img[src*="pdf"] {
			width: 80px !important;
			min-width: 80px !important;
			border-radius: 4px;
			
			&:hover {
				box-shadow: 0 0 4px 0 rgba(0,0,0,0.35);
			}
		}
	}
	
	.uk-overflow-auto {
		padding-bottom: 40px;
	}
	
	.pager {
		margin-top: 30px;
		
		span,
		a {
			text-decoration: none;
			text-align: center !important;
			display: inline-block;
			margin: 0;
			
			&.uk-text-muted {
				margin-right: 8px;
			}
		}
		
		a {
			color: @brand-primary;
			width: 30px;
			height: 36px;
			line-height: 35px;
			padding: 0;
			
			&.active {
				color: #fff;
				background-color: @brand-primary;
				border-radius: 50%;
				cursor: default;
				width: 36px;
			}
		}
	}
}

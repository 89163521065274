/* ========================================================================
   Card
 ========================================================================== */
 
.card-data {
	font-size: 14px;
	
	dd {
		margin-bottom: 14px;
	}
	
	td {
		vertical-align: middle;
		text-align: left;
	}
	
	img[alt="card"] {
		min-width: 100px;
	}
}

.card-image{
	box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.35);
	border-radius: 22px;
}

.uk-card-default {
	background-color: #fff;
}


.icon-menu {
	white-space: nowrap;
	
	a {
		width: 32px;
		height: 32px;
		display: inline-block;
		margin-right: 12px;
		
		background-position: 0 0;
		background-repeat: no-repeat;
		background-size: cover;
		
		&.rinnova {
			background-image: url(../images/icon/rinnova.svg);
		}
		
		&.modifica {
			background-image: url(../images/icon/modifica.svg);
		}
		
		&.stampa {
			background-image: url(../images/icon/stampa.svg);
		}
		
		&.abbonamento {
			background-image: url(../images/icon/abbonamento.svg);
		}
		
		&.cancella {
			background-image: url(../images/icon/cancella.svg);
		}
	}
}
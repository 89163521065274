/* ========================================================================
   Modal
 ========================================================================== */
 
.uk-modal-dialog {
	width: 670px;
}

.uk-modal-footer {
	padding: 15px 30px 5px 30px;
	
	.uk-button {
		margin-bottom: 10px;
	}
}
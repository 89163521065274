// Mixins
//----------------------------


/*
 * Position absolut Centered
 */
.absolut-centered() {
  position: absolute;
	top: 50%;
  left: 50%;
  .translate(-50%, -50%);
}

.absolut-centered-left() {
  position: absolute;
	top: 50%;
  left: 0;
  .translate(0, -50%);
}

.absolut-centered-right() {
  position: absolute;
	top: 50%;
	left: auto;
  right: 0;
  .translate(0, -50%);
}

.absolut-centered-bottom() {
  position: absolute;
	bottom: 0;
  left: 50%;
  .translate(-50%, 0);
}

.absolut-centered-top() {
  position: absolute;
	top: 0;
  left: 50%;
  .translate(-50%, 0);
}

.absolut-centered-reset() {
  position: relative;
	top: auto;
  left: auto;
  .translate(0, 0);
}
// Form
//----------------------------

.recaptcha-container {
	max-width: 280px;
}

.g-recaptcha {
  transform-origin: left top;
  -webkit-transform-origin: left top;
}

form,
.form {
	text-align: left;
}
	
fieldset {
	background-color: #fff;
	border: 1px solid #cbcbcb;
	border-radius: 14px;
	
	margin: 0 auto 30px;
	padding: 48px 40px 30px 40px;
	max-width: 520px;
}

.fieldset-width {
	margin: 0 auto 30px;
	max-width: 540px;
}


legend {
	padding: 0 20px;
	margin-left: -20px;
	text-transform: uppercase;
	
	& + .uk-margin {
		margin-top: -10px !important;
	}
}





.uk-form-label {
	font-size: 14px;
	font-weight: 700;
}
	
.uk-select,
.uk-input,
.uk-textarea {
	border-radius: 3px;
	
	&[readonly] {
		background-color: rgba(151,151,151,0.2);
		border: 1px solid #cacaca;
		color: #999;
	}
	
	&.uk-form-danger {
		border: 1px solid @global-danger-background;
	}
}

.uk-select,
.uk-input,
.uk-textarea,
.uk-radio,
.uk-checkbox {
	border: 1px solid #979797;
	background-color: #fff;
}

.uk-form-danger {
	
	.uk-select,
	.uk-input,
	.uk-radio,
	.uk-checkbox,
	.uk-textarea {
		border: 1px solid @global-danger-background;
	}
	
	span {
		color: @global-danger-background;
	}
}


.uk-radio:checked,
.uk-checkbox:checked,
.uk-checkbox:indeterminate {
	background-color: @gray;
}


.uk-form-custom {
	display: block;
}

form {
	
	.uk-button-default {
		background: #fff;
		text-transform: none;
		font-weight: 700;
		border: 1px solid #ccc;
		border-radius: 3px;
		
		padding-top: 8px;
		padding-bottom: 8px;
	}
}



/* input + button rounded */
.input-rounded {
	border-radius: 30px;
	padding: 0 20px;
	
	&.input-gray {
		border: 0;
		background-color: @gray-lighter;
	}
	
	+ .button-rounded {
		float: right;
		position: relative;
		top: -40px;
	}
}





/* PASSWORD METER */

meter {
  /* Reset the default appearance */
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
            
	margin: 0 auto 1em;
	width: 100%;
	height: .5em;
    
  /* Applicable only to Firefox */
	background: none;
	background-color: rgba(0,0,0,0.1);
	
	&::-webkit-meter-bar {
		background: none;
		background-color: rgba(0,0,0,0.1);
	}
	
	> div {
		height: 100%;
		margin: 0 auto 1em 0;
		width: 100%;
		height: .5em;
	}
	
	&[value="1"] {
		
		&::-moz-meter-bar { background: red; }
		
		> div {
			width: 25%;
			background: red;
		}
	}
	
	&[value="2"] {
		
		&::-moz-meter-bar { background: yellow; }
		
		 > div {
			width: 50%;
			background: yellow;
		}
	}
	
	&[value="3"] {
		
		&::-moz-meter-bar { background: orange; }
		
		> div {
			width: 75%;
			background: orange;
		}
	} 
	
	&[value="4"] {
		
		&::-moz-meter-bar { background: green; }
		
		> div {
			width: 100%;
			background: green;
		}
	} 
	
	+ p {
		margin-top: -14px;
	}
}

.feedback {
	padding: 0 .25em;
	margin-top: 1em;
	color: @global-danger-background;
}







.private {
	
	fieldset {
		border: 0;
		.box-shadow( 0 1px 4px 0 rgba(0,0,0,0.35));
	}

	legend {
		position: relative;
		top: 50px;
		margin-bottom: 40px;
	}
}



// Responsive
//----------------------------

@media only screen and (max-width: 478px) {

	.uk-button-large {
		font-size: 1rem;
	}
	
	fieldset {
		padding: 30px 20px;
	}
	
	legend {
		padding: 0 20px 0 10px;
		margin-left: -10px;
	}
}


@media only screen and (max-width: 419px) {	

	/* input + button rounded */
	.input-rounded {
		
		+ .button-rounded {
			float: none;
			position: relative;
			top: 4px;
			width: 100%;
		}
	}
}
/* ========================================================================
   preloader 
 ========================================================================== */
 
 
#preloader {
	position: fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background-color: @brand-secondary; /* change if the mask should be a color other than white */
	z-index:2000; /* makes sure it stays on top */
	
	img {
		.absolut-centered();
		width: 40vw;
	}
}


// Variables
//----------------------------


//
// Colors
//
@gray:																 #313131;
@gray-light: 													 lighten(@gray, 26%);
@gray-lighter: 												 #e3e6e8;

@brand-gray:													 #5a5a5a; // Gray
@brand-primary: 											 #C41B04; // Red
@brand-secondary:          						 #2A368C; // Blue

@global-primary-background:            #C41B04;
@global-secondary-background:          #2A368C;


@global-link-color:                    #C41B04;
@global-link-hover-color:              #dc1e04;

@base-em-color:                        @gray;
	

//
// Typography
//

@base-body-font-family:               'Montserrat', Verdana, Geneva, sans-serif;
@base-body-font-weight:               normal;
@base-body-font-size:                 16px;
@base-body-color:                     @brand-gray;


@base-heading-font-family:            @base-body-font-family;
@base-heading-font-weight:            700;
@base-heading-color:                  #393939;
@base-heading-line-height:    				1.1;


@global-small-font-size:              0.75rem; // 12px



@global-line-height:                  1.5;      // 24px
@text-small-line-height:              1;


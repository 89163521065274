/* ========================================================================
   Buttons
 ========================================================================== */
 
.uk-button {
	border-radius: 0;
	line-height: 1.4;
	padding-top: 12px;
	padding-bottom: 12px;
	
	text-transform: uppercase;
	
	&.uk-icon {
		padding-right: 60px;
		text-align: left;

		&:hover > svg {
			.translate(10px, -50%);
		}
		svg {
			.absolut-centered-right();
			right: 20px;
			.transition(all .5s ease);
		}
	}
	
	&.uk-button-default {
		border-radius: 0;
		line-height: 1.4;
		padding-top: 11px;
		padding-bottom: 11px;
		
		text-transform: uppercase;
		font-weight: normal;
	}
	
	&.button-light {
		background-color: transparent;
		border: 1px solid @brand-primary;
		color: @brand-primary;
		border-radius: 60px;
	
		text-transform: none;
		
		&:hover {
			background-color: @brand-primary;
			color: #fff;
		}
	}
	
	&.button-rounded {
		border-radius: 40px;
		padding: 9px 30px;
	}
}
/* ========================================================================
   Jumbotron
 ========================================================================== */

.jumbotron {
	position: relative;
	top: -1px;
	height: 84vh !important;
	min-height: 500px;
	
	background-image: url(../images/home/jumbotron.jpg);
	background-repeat: no-repeat;
	background-position: 0 30%;
	background-size: cover;
	
	.content {
		z-index: 2;
		margin-left: 7vw;
		
		h1, h2, p, span, em {
			color: #fff;
			text-shadow: 0 1px 3px rgba(0,0,0,0.3);
		}
		
		h1 {
			font-size: 3.8rem;
		}
		
		.uk-button {
			margin-top: 40px;
			font-size: 1.4rem;
		}
	}
	
	&:after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.1);
		z-index: 1;
	}
}




// Responsive
//----------------------------



@media only screen and (max-width: 1200px) {

	.jumbotron {
	
		.content {
			margin-left: 5vw;
			
			h1, .uk-h1 {
    		font-size: 3rem;
    	}
		
			.uk-button {
				font-size: 1.2rem;
			}
		}
	}
}

@media only screen and (max-width: 720px) {

	.jumbotron {
		background-position: 40% 30%;
	
		.content {
			
			h1, .uk-h1 {
    		font-size: 2.25rem;
    	}
		
			.uk-button {
				margin-top: 20px;
				font-size: 1.1rem;
			}
		}
	}
}


@media only screen and (max-width: 560px) {

	.jumbotron {
		background-position: 64% 30%;
	
		.content {
			margin-left: 4vw;
			
			h1, .uk-h1 {
    		font-size: 1.8rem;
    	}
		
			.uk-button {
				font-size: 1rem;
				padding-left: 20px;
				padding-right: 20px;
			}
		}
	}	
}


@media only screen and (max-width: 480px) {

	.jumbotron {
		min-height: 220px;
		background-position: 80% 0;
	
		.content {
			position: relative;
			margin-left: auto;
			max-width: 100%;
			padding: 20px 20px 0;
			top: 0;
			.translate(0,0);
		
			.uk-button {
				font-size: 0.8rem;
				margin-top: 0;
			}
		}
	}	
}




@media only screen and (max-width: 768px) and (min-height: 1024px ) {

	.jumbotron {
		background-position: 50% 30%;
	}
}

@media only screen and (max-width: 800px) and (min-height: 1280px ) {

	.jumbotron {
		background-position: 50% 30%;
	}
}

// Name:            Margin
// Description:     Utilities for margins
//
// Component:       `uk-margin-*`
//                  `uk-margin-small-*`
//                  `uk-margin-medium-*`
//                  `uk-margin-large-*`
//                  `uk-margin-xlarge-*`
//                  `uk-margin-remove-*`
//                  `uk-margin-auto-*`
//
// ========================================================================


// Variables
// ========================================================================

@margin-margin:                                        @global-margin;

@margin-small-margin:                                  @global-small-margin;

@margin-medium-margin:                                 @global-medium-margin;

@margin-large-margin:                                  @global-medium-margin;
@margin-large-margin-l:                                @global-large-margin;

@margin-xlarge-margin:                                 @global-large-margin;
@margin-xlarge-margin-l:                               @global-xlarge-margin;


/* ========================================================================
   Component: Margin
 ========================================================================== */

/*
 * Default
 */

.uk-margin { margin-bottom: @margin-margin; }
* + .uk-margin { margin-top: @margin-margin !important; }

.uk-margin-top { margin-top: @margin-margin !important; }
.uk-margin-bottom { margin-bottom: @margin-margin !important; }
.uk-margin-left { margin-left: @margin-margin !important; }
.uk-margin-right { margin-right: @margin-margin !important; }


/* Small
 ========================================================================== */

.uk-margin-small { margin-bottom: @margin-small-margin; }
* + .uk-margin-small { margin-top: @margin-small-margin !important; }

.uk-margin-small-top { margin-top: @margin-small-margin !important; }
.uk-margin-small-bottom { margin-bottom: @margin-small-margin !important; }
.uk-margin-small-left { margin-left: @margin-small-margin !important; }
.uk-margin-small-right { margin-right: @margin-small-margin !important; }


/* Medium
 ========================================================================== */

.uk-margin-medium { margin-bottom: @margin-medium-margin; }
* + .uk-margin-medium { margin-top: @margin-medium-margin !important; }

.uk-margin-medium-top { margin-top: @margin-medium-margin !important; }
.uk-margin-medium-bottom { margin-bottom: @margin-medium-margin !important; }
.uk-margin-medium-left { margin-left: @margin-medium-margin !important; }
.uk-margin-medium-right { margin-right: @margin-medium-margin !important; }


/* Large
 ========================================================================== */

.uk-margin-large { margin-bottom: @margin-large-margin; }
* + .uk-margin-large { margin-top: @margin-large-margin !important; }

.uk-margin-large-top { margin-top: @margin-large-margin !important; }
.uk-margin-large-bottom { margin-bottom: @margin-large-margin !important; }
.uk-margin-large-left { margin-left: @margin-large-margin !important; }
.uk-margin-large-right { margin-right: @margin-large-margin !important; }

/* Desktop and bigger */
@media (min-width: @breakpoint-large) {

    .uk-margin-large { margin-bottom: @margin-large-margin-l; }
    * + .uk-margin-large { margin-top: @margin-large-margin-l !important; }

    .uk-margin-large-top { margin-top: @margin-large-margin-l !important; }
    .uk-margin-large-bottom { margin-bottom: @margin-large-margin-l !important; }
    .uk-margin-large-left { margin-left: @margin-large-margin-l !important; }
    .uk-margin-large-right { margin-right: @margin-large-margin-l !important; }

}


/* XLarge
 ========================================================================== */

.uk-margin-xlarge { margin-bottom: @margin-xlarge-margin; }
* + .uk-margin-xlarge { margin-top: @margin-xlarge-margin !important; }

.uk-margin-xlarge-top { margin-top: @margin-xlarge-margin !important; }
.uk-margin-xlarge-bottom { margin-bottom: @margin-xlarge-margin !important; }
.uk-margin-xlarge-left { margin-left: @margin-xlarge-margin !important; }
.uk-margin-xlarge-right { margin-right: @margin-xlarge-margin !important; }

/* Desktop and bigger */
@media (min-width: @breakpoint-large) {

    .uk-margin-xlarge { margin-bottom: @margin-xlarge-margin-l; }
    * + .uk-margin-xlarge { margin-top: @margin-xlarge-margin-l !important; }

    .uk-margin-xlarge-top { margin-top: @margin-xlarge-margin-l !important; }
    .uk-margin-xlarge-bottom { margin-bottom: @margin-xlarge-margin-l !important; }
    .uk-margin-xlarge-left { margin-left: @margin-xlarge-margin-l !important; }
    .uk-margin-xlarge-right { margin-right: @margin-xlarge-margin-l !important; }

}


/* Remove
 ========================================================================== */

.uk-margin-remove { margin: 0 !important; }
.uk-margin-remove-top { margin-top: 0 !important; }
.uk-margin-remove-bottom { margin-bottom: 0 !important; }
.uk-margin-remove-left { margin-left: 0 !important; }
.uk-margin-remove-right { margin-right: 0 !important; }

.uk-margin-remove-vertical {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.uk-margin-remove-adjacent + * { margin-top: 0 !important; }


/* Auto
 ========================================================================== */

.uk-margin-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}

.uk-margin-auto-top { margin-top: auto !important; }
.uk-margin-auto-bottom { margin-bottom: auto !important; }
.uk-margin-auto-left { margin-left: auto !important; }
.uk-margin-auto-right { margin-right: auto !important; }

.uk-margin-auto-vertical {
    margin-top: auto !important;
    margin-bottom: auto !important;
}


// Hooks
// ========================================================================

.hook-margin-misc;

.hook-margin-misc() {}

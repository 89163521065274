// Navigation
//----------------------------

.navbar {
	width: 100%;
	background-color: #fff;
	overflow: hidden;
	border-bottom: 1px solid #c5c5c5;
	
	&.uk-navbar-container {
		background-color: #fff;
	}
	
	.logo img {
		width: 175px;
	}
	
	
	.uk-navbar-nav > li {
		
		> a {
		  height: 64px;
		  text-transform: uppercase;
		}
		
		.uk-button {
			color: #fff;
			padding-left: 34px;
			padding-right: 34px;
		}
		
		.logout {
			border-left: 1px solid rgba(255,255,255,0.4);
			padding-left: 24px;
			padding-right: 24px;
			
			img {
				width: 26px;
			}
		}
	}
	
	.uk-dropdown {
		background-color: @brand-primary;
		top: 54px !important;
		min-width: 181px !important;
		
		.uk-dropdown-nav {
		 	
		 	> li > a {
				color: rgba(255,255,255,0.7);
			
				&:hover {
					color: #fff;
				}
			}
			
			.uk-nav-divider {
				border-top-color: rgba(0,0,0,0.3);
			}
		}
	}
	
	
	.backlink {
		margin-left: 10px;
		color: @brand-primary;
	}
}





.private .navbar {
	
	.uk-navbar-nav {
		
		> li {
		
			> .username {
				display: block;
				line-height: 64px;
			  text-transform: none;
			  max-width: 240px;
			  min-width: 140px;
			  
			  padding-right: 54px;
			  background-image: url("../images/icon/chevron-down.svg");
			  background-repeat: no-repeat;
			  background-position: calc(~"100% - 30px") 51%;
			  background-size: 20px 20px;
			}
		}
		
		.uk-card {
			background-color: @brand-primary;
			
			a {
				display: block;
				padding: 16px 10px;
				border-top: 1px solid rgba(0,0,0,0.1);
				
				text-align: center;
				color: #fff;
				text-decoration: none;
				font-size: 12px;
				
				&:hover {
					background-color: darken(@brand-primary, 4%);
				}
			}
		}
	}
	
	.logo img {
		width: 175px !important;
	}
}
		
		


// Responsive
//----------------------------


@media only screen and (min-width: 640px) {

	.private .navbar {
		
		.uk-navbar-nav {
			max-width: 50vw;
		}
	}
}


@media only screen and (max-width: 520px) {	
	

	.private .navbar {
		
		.uk-float-left,
		.uk-float-right,
		.uk-float-left li {
			width: 100% !important;
			text-align: center;
			float: none !important;
		}
		
		.uk-float-right {
			background-color: @brand-primary;
			height: 64px;
			
			ul {
				margin: 0;
				float: right;
			}
		}
	}
}



@media only screen and (max-width: 390px) {	
	

	.navbar {
		
		.uk-navbar-left {
			width: 50%;
		}
		
		.logo img {
			width: 120px;
		}
		
		li > a {
			font-size: 14px;
		}
	}
}


/* ========================================================================
   Dropzone
 ========================================================================== */
 
.dropzone {
  border: 1px dashed @gray-light;
  border-radius: 10px;
  text-align: center;
  
  .dz-message {
  	background-image: url(../images/icon/upload.svg);
  	background-position: 50% 0;
  	background-repeat: no-repeat;
  	background-size: 80px 57px;
  	
  	padding-top: 64px;
  	margin-top: 1em;
  	
  	span {
  		font-size: 16px;
  	}
  }
  
  .dz-preview,
  .dz-preview.dz-file-preview {
  	text-align: center;
  	
  	.dz-image {
	    border-radius: 6px;
	    margin: 0 auto;
	    width: 120px;
	    height: 147px;
    }
  }
  
  .dz-image:after {
  	content: "";
	  position: absolute;
	  width: 100%;
	  height: 100%;
	  top: 0;
	  left: 0;
	  
  	background-repeat: no-repeat;
  	background-position: 50% 50%;
  	background-size: 138px 180px;
  }
  
  .dz-image.dz-icon-pdf:after {
	  background-image: url(../images/icon/pdf.svg);
  }
  
  .dz-image.dz-icon-doc:after {
	  background-image: url(../images/icon/doc.svg);
  }
  
  .dz-image.dz-icon-docx:after {
	  background-image: url(../images/icon/docx.svg);
  }
  
  .dz-image.dz-icon-odt:after {
	  background-image: url(../images/icon/odt.svg);
  }
  
  
  .dz-remove {
  	background-color: @brand-primary;
  	color: #fff;
  	margin-top: 10px;
  	text-decoration: none;
  	text-transform: uppercase;
  	padding: 6px 12px;
  	font-size: 12px;
  	display: block;
  	
  	&:hover {
  		text-decoration: none !important;
  	}
  }
}



/**/
.form-update {
  
	.dz-message {
		background-image: none;
		padding-top: 0;
	}
	
	.mymessage {
  	background-image: url(../images/icon/upload.svg);
  	background-position: 50% 0;
  	background-repeat: no-repeat;
  	background-size: 80px 57px;
  	
  	position: relative;
  	display: block;
  	padding-top: 64px;
  	margin-top: 2em;
	}
	
	.mythumbnail {
		border-radius: 6px;
		max-width: 120px;
		position: relative;
	}
}


// Base
//----------------------------

html, body {
	height: 100%;
	font-family: @base-body-font-family !important;
  
  &.private {
  	background-color: #f3f3f3;
  }
}

body > .content {
  min-height: calc(~ "100vh - 200px");
}

.brand-color {
	color: @brand-primary;
}

// Links

a {
	.transition(all .5s ease);
}

a,
.underline {
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: none;
  }
  
  &.underline-hover {
  	text-decoration: none;
  	&:hover {
  		text-decoration: underline;
  	}
  }
  &.no-underline {
  	text-decoration: none;
  }
}



// Responsive
//----------------------------


@media only screen and (max-width: 959px) {	

	.container-small-under-medium {
		max-width: 620px;
	}
}